import {ProductItem} from "./product-item.model";

export class ProductGroup {
  id: string;
  name: string;
  imagePath: string;
  description: string;
  comment: string;
  examinationRequired: boolean;
  examinationIntervalMonths: number;
  productItemsTotal: number;
  productItemsDamageReportsBrokenTotal: number;
  productItemsAvailable: number;
  productItems: ProductItem[];
}
