import {MatProgressButtonOptions} from 'mat-progress-buttons';
import {MatDialogConfig} from '@angular/material/dialog';
import {MatSortable} from "@angular/material/sort";
import {BarcodeFormat} from "@zxing/library";
import {
  Plan,
  PlansFilter,
  ProductGroupsFilter,
  ProductItemsFilter, ProjectsFilter,
  QrStickersOrdersFilter,
  UsersFilter
} from '@app/core/models';
import {ProductItemExaminationsFilter} from "@app/core/models/product-item-examinations-filter.model";

const appDialogConfig = new MatDialogConfig();
appDialogConfig.maxWidth = null;
appDialogConfig.maxHeight = null;
appDialogConfig.panelClass = 'app-dialog';

const fullScreenDialogConfig = new MatDialogConfig();
fullScreenDialogConfig.maxWidth = null;
fullScreenDialogConfig.maxHeight = null;
fullScreenDialogConfig.panelClass = 'full-screen-dialog';

// `src/styles/exports/_vars.scss`
const breakpoints = [
  {name: 'mp', endsAt: 480},
  {name: 'ml', endsAt: 680},
  {name: 'tp', endsAt: 800},
  {name: 'tl', endsAt: 992},
  {name: 'd', endsAt: 1200},
  {name: 'dl', endsAt: 1600},
  {name: 'dxl', endsAt: 9999},
];
const breakPointRanges: { min: number, max: number, name: string }[] = [];
breakpoints.forEach((breakpoint, i) => {
  let min = 0;
  if (i > 0) {
    min = breakpoints[i - 1].endsAt + 1;
  }
  breakPointRanges.push({min: min, max: breakpoint.endsAt, name: breakpoint.name})
});

export const appConfig = {
  // User types
  userTypes: [
    'admin', 'employee'
  ],

  // ExaminationIntervals
  examinationIntervals: [
    {months: 1, label: '1 maand'},
    {months: 2, label: '2 maanden'},
    {months: 3, label: '3 maanden'},
    {months: 4, label: '4 maanden'},
    {months: 5, label: '5 maanden'},
    {months: 6, label: '6 maanden'},
    {months: 7, label: '7 maanden'},
    {months: 8, label: '8 maanden'},
    {months: 9, label: '9 maanden'},
    {months: 10, label: '10 maanden'},
    {months: 11, label: '11 maanden'},
    {months: 12, label: '1 jaar'},
    {months: 18, label: '1,5 jaar'},
    {months: 24, label: '2 jaar'},
    {months: 30, label: '2,5 jaar'},
    {months: 36, label: '3 jaar'},
    {months: 48, label: '4 jaar'},
    {months: 60, label: '5 jaar'},
  ] as { months: number, label: string }[],

  // sessionStorage
  sessionStorageKeys: {
    successfulScan: 'successfulScan'
  },
  // localStorage
  localStorageKeys: {
    email: "email",
    user: "user",
    company: "company",
    qrStickersOrder: "qrStickersOrder",
    lastSelectedProjectId: "lastSelectedProjectId",
    tables: {
      users: {
        sort: 'usersTableSort',
        filter: 'usersTableFilter',
        pagination: 'usersTablePagination',
      },
      productGroups: {
        sort: 'productGroupsTableSort',
        filter: 'productGroupsTableFilter',
        pagination: 'productGroupsTablePagination',
      },
      productItems: {
        sort: (tableId = 'productItemsTable') => {
          return `${tableId}Sort`
        },
        filter: (tableId = 'productItemsTable') => {
          return `${tableId}Filter`
        },
        pagination: (tableId = 'productItemsTable') => {
          return `${tableId}Pagination`
        },
      },
      productItemExaminations: {
        sort: 'productItemExaminationsTableSort',
        filter: 'productItemExaminationsTableFilter',
        pagination: 'productItemExaminationsTablePagination',
      },
      projects: {
        sort: 'projectsTableSort',
        filter: 'projectsTableFilter',
        pagination: 'projectsTablePagination',
      },
      plans: {
        sort: 'plansTableSort',
        filter: 'plansTableFilter',
        pagination: 'plansTablePagination',
      },
      companies: {
        sort: 'companiesTableSort',
        filter: 'companiesTableFilter',
        pagination: 'companiesTablePagination',
      },
      qrStickersOrders: {
        sort: 'qrStickersOrdersTableSort',
        filter: 'qrStickersOrdersTableFilter',
        pagination: 'qrStickersOrdersTablePagination',
      },
    },
  },

  // Default Options
  yesNoOptions: [
    {value: true, label: 'Ja'},
    {value: false, label: 'Nee'},
  ],

  pageSizeOptions: [
    25, 50, 100, 250
  ],
  defaultTableSorting: {
    users: {id: 'name', start: 'asc'} as MatSortable,
    productGroups: {id: 'name', start: 'asc'} as MatSortable,
    productItems: {id: 'name', start: 'asc'} as MatSortable,
    productItemExaminations: {id: 'deadlineDate', start: 'asc'} as MatSortable,
    projects: {id: 'name', start: 'asc'} as MatSortable,
    plans: {id: 'name', start: 'asc'} as MatSortable,
    companies: {id: 'name', start: 'asc'} as MatSortable,
    qrStickersOrders: {id: 'createdDate', start: 'asc'} as MatSortable,
  },
  defaultTableFilters: {
    users: {query: null} as UsersFilter,
    productGroups: {query: null} as ProductGroupsFilter,
    productItems: {query: null, examinationExpireStatus: null} as ProductItemsFilter,
    productItemExaminations: {query: null} as ProductItemExaminationsFilter,
    projects: {query: null, isActive: true} as ProjectsFilter,
    plans: {query: null} as PlansFilter,
    companies: {query: null} as PlansFilter,
    qrStickersOrders: {query: null} as QrStickersOrdersFilter,
  },

  // Components
  appDialogConfig: appDialogConfig as MatDialogConfig,
  fullScreenDialogConfig: fullScreenDialogConfig as MatDialogConfig,

  // Spinner Btns
  btnOptions: {
    active: false,
    text: 'Verstuur',
    raised: true,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    spinnerSize: 18,
    stroked: false,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate',
    buttonIcon: {
      fontIcon: 'send'
    }
  } as MatProgressButtonOptions,

  // misc
  dateTimeFormat: 'dd-MM-yyyy HH:mm:ss',
  dateFormat: 'dd-MM-yyyy',
  maxSearchResults: 200,
  breakPointRanges: breakPointRanges,
  scanFormats: [BarcodeFormat.QR_CODE] as BarcodeFormat[],
  scanValidTimeMinutes: 5,
  platformWebsite: 'https://www.qr-database.nl/faq/',
  dateMask: {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  },
  specialKeys: [
    'Backspace',
    'Tab',
    'Enter',
    'Shift',
    'Control',
    'Alt',
    'CapsLock',
    'Escape',
    'PageUp',
    'PageDown',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowUp',
    'ArrowRight',
    'ArrowDown',
    'PrintScreen',
    'Insert',
    'Delete',
  ],
}
