import {
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatMenuTrigger} from "@angular/material/menu";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {appConfig, ProductGroupsFilter} from "@app/core";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-product-groups-filter',
  templateUrl: './product-groups-filter.component.html',
  styleUrls: ['./product-groups-filter.component.scss']
})
export class ProductGroupsFilterComponent implements OnInit, OnDestroy {
  @Output() formUpdate: EventEmitter<ProductGroupsFilter> = new EventEmitter<ProductGroupsFilter>();
  form: UntypedFormGroup;
  selectedFiltersCount: number;
  applyFiltersButtonText = '';
  appliedFiltersCount = 0;
  private form$: Subscription;

  @ViewChild('menuTrigger', {static: true}) menuTrigger: MatMenuTrigger;
  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.menuTrigger.closeMenu();
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,) {
  }

  ngOnInit(): void {
    this.initForm();
    this.bindToMenuOpen();
  }

  private bindToMenuOpen() {
    this.changeDetectorRef.detectChanges();
    this.menuTrigger.menuOpened.subscribe(() => {
      this.searchInput.nativeElement.focus();
    })
  }

  private initForm() {
    this.createFormFields();
    this.subscribeToForm();
    this.setInitialFormValues();
    this.setFilterCountAndEmit(this.form.getRawValue())
  }

  private createFormFields() {
    this.form = new UntypedFormGroup({
      query: new UntypedFormControl(null),
    });
  }

  private subscribeToForm() {
    this.form$ = this.form.valueChanges.subscribe(form => {
      if(this.selectedFiltersCount !== undefined) {
        this.form.markAsTouched();
        this.form.markAsDirty();
      }
      this.handleFormValueUpdate(form);
    });
  }

  private handleFormValueUpdate(form) {
    this.setSelectedFiltersCount(form);
    this.setApplyFiltersButtonText();
  }

  private setSelectedFiltersCount(form) {
    const selectedFilters: ProductGroupsFilter = Object.assign(new ProductGroupsFilter(), form).getTrimmedFilters();
    if (selectedFilters) {
      this.selectedFiltersCount = Object.keys(selectedFilters).length;
    } else {
      this.selectedFiltersCount = 0;
    }
  }

  private setApplyFiltersButtonText() {
    if (this.selectedFiltersCount === 0) {
      return this.applyFiltersButtonText = 'Pas geen filters toe';
    }
    if (this.selectedFiltersCount === 1) {
      return this.applyFiltersButtonText = 'Pas 1 filter toe';
    }

    return this.applyFiltersButtonText = `Pas ${this.selectedFiltersCount} filters toe`;
  }

  private setInitialFormValues() {
    const initialFormValues: ProductGroupsFilter = this.getInitialFormValues();
    this.form.patchValue(initialFormValues);
  }

  private getInitialFormValues(): ProductGroupsFilter {
    const localStorageString = localStorage.getItem(appConfig.localStorageKeys.tables.productGroups.filter);
    if (!localStorageString) {
      return appConfig.defaultTableFilters.productGroups;
    }
    return JSON.parse(localStorageString) as ProductGroupsFilter;
  }

  private setFilterCountAndEmit(formValues: ProductGroupsFilter) {
    const trimmedFilters = Object.assign(new ProductGroupsFilter(), formValues).getTrimmedFilters();
    if (trimmedFilters) {
      this.appliedFiltersCount = Object.keys(trimmedFilters).length;
    } else {
      this.appliedFiltersCount = 0;
    }
    this.formUpdate.emit(trimmedFilters);
  }

  onSubmit() {
    const formValues = this.form.getRawValue();
    localStorage.setItem(appConfig.localStorageKeys.tables.productGroups.filter, JSON.stringify(formValues))
    this.setFilterCountAndEmit(formValues);
    this.menuTrigger.closeMenu();
  }

  onClearFiltersClick() {
    this.form.reset();
    this.onSubmit();
  }

  ngOnDestroy(): void {
    if (this.form$) {
      this.form$.unsubscribe();
    }
  }
}

