import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {WebSocketService} from "@app/core/services/web-socket.service";
import {appConfig, User} from "@app/core";
import {MatProgressButtonOptions} from "mat-progress-buttons";
import {Router} from "@angular/router";
import {UserService} from "@app/core/services/user.service";

@Component({
  selector: 'app-web-socket-error-dialog',
  templateUrl: './web-socket-error-dialog.component.html',
  styleUrls: ['./web-socket-error-dialog.component.scss']
})
export class WebSocketErrorDialogComponent implements OnInit {
  @ViewChild('buttonText', {static: true}) buttonText: ElementRef;
  errorMessage: string;
  button: MatProgressButtonOptions;
  user: User;
  webSocketService: WebSocketService;
  forceReconnectWithCompanyId = '';

  constructor(
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<WebSocketErrorDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) matDialogData: {
      webSocketService: WebSocketService,
      forceReconnectWithCompanyId: string,
      errorMessage: string
    }
  ) {
    this.webSocketService = matDialogData.webSocketService;
    this.forceReconnectWithCompanyId = matDialogData.forceReconnectWithCompanyId;
    this.errorMessage = matDialogData.errorMessage;
  }

  ngOnInit(): void {
    this.setButton();
    this.setUser();
    this.tryAutoReconnect();
  }

  private tryAutoReconnect() {
    if (!this.webSocketService.webSocketAutoRetryPending) {
      this.webSocketService.webSocketAutoRetryPending = true;
      this.onReconnectClick();
    }
  }

  private setButton() {
    this.changeDetectorRef.detectChanges();
    const button = Object.assign({}, appConfig.btnOptions);
    button.text = this.buttonText.nativeElement.innerText;
    button.buttonColor = 'accent';
    button.buttonIcon = {
      fontIcon: 'refresh'
    }
    this.button = button;
  }

  private setUser() {
    this.user = this.userService.getUser();
  }

  onReconnectClick() {
    if (this.button.active) {
      return null;
    }
    this.button.active = true;
    if (this.forceReconnectWithCompanyId) {
      this.webSocketService.connect({publicCompanyId: this.forceReconnectWithCompanyId});
    } else {
      this.webSocketService.connect({apiToken: this.user.apiToken});
    }
  }

  onLogOutClick() {
    this.userService.unSetUser();
    this.dialog.closeAll();
    this.router.navigateByUrl('/').then();
  }

}
