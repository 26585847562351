import {Loan} from "@app/core/models/loan.model";
import {DamageReport} from "@app/core/models/damage-report.model";

export class User {
  id: string;
  apiToken?: string;
  name?: string;
  email?: string;
  apiTokenExpire?: Date;
  role?: 'admin' | 'employee' | 'superAdmin';
  adminCanViewOnly: boolean;
  currentLoans?: number;
  totalLoans?: number;
  loginType?: 'local' | 'AAD';
  AADAccount?: string;
  lastLogin?: Date;
  secondToLastLogin?: Date;
  isActive?: boolean;
  isMainContact?: boolean;
  emptyPassword?: boolean;
  companyName?: string;
  companyId?: string;
  loans?: Loan[];
  damageReports?: DamageReport[];
}
