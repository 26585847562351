import {Component, OnInit} from '@angular/core';
import {UserService} from './core/services/user.service';
import * as moment from 'moment';
import {appConfig} from "@app/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private userService: UserService
  ) {
    moment.locale('nl');
  }

  ngOnInit(): void {
    this.setUserUsingLocalStorage();
  }

  private setUserUsingLocalStorage() {
    const storage = localStorage.getItem(appConfig.localStorageKeys.user);
    if (!storage) {
      return null;
    }
    const user = JSON.parse(storage);
    this.userService.setUser(user);
  }
}
