declare const require: any;

export const environment = {
  production: false,
  useProxy: false,
  version: require('../../package.json').version,
  apiBaseUrl: 'https://qr-test.dysto.app',
  apiPrefix: 'QR_Test/webapp',
  ws: 'wss://qr-test.dysto.app:8889/QR',
  imagePath: 'https://images.qr-database.nl/Test/',
};
