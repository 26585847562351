<h2>Bevestig</h2>
<mat-dialog-content>
  <p [innerHTML]="text"></p>
</mat-dialog-content>

<mat-dialog-actions>
  <button *ngIf="!closeOnly" mat-button (click)="cancel()">
    <mat-icon>clear</mat-icon>
    Annuleren
  </button>
  <button mat-raised-button (click)="confirm()" color="accent">
    <ng-container *ngIf="!closeOnly">
      <mat-icon>thumb_up_alt</mat-icon>
      Bevestig
    </ng-container>
    <ng-container *ngIf="closeOnly">
      <mat-icon>check</mat-icon>
      Begrepen
    </ng-container>
  </button>
</mat-dialog-actions>
