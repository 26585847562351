import {Component, Input, OnInit} from '@angular/core';
import {appConfig} from "@app/core";

@Component({
  selector: 'app-product-group-interval-months',
  templateUrl: './product-group-interval-months.component.html',
  styleUrls: ['./product-group-interval-months.component.scss']
})
export class ProductGroupIntervalMonthsComponent implements OnInit {
  @Input() productGroupExaminationIntervalMonths: number
  label: string;
  appConfig = appConfig;

  constructor() {
  }

  ngOnInit(): void {
    const interval = appConfig.examinationIntervals.find(examinationInterval => {
      return examinationInterval.months === this.productGroupExaminationIntervalMonths
    });
    if (interval) {
      this.label = interval.label;
    } else {
      this.label = '-';
    }
  }

}
