import {CentsToEurosPipe} from "@app/dashboard/modules/dashboard-shared/pipes/cents-to-euros.pipe";

export class QrStickersPricing {
  baseEuroCents: number;
  _baseEuros?: number;

  euroCentsPerSticker: number;
  _eurosPerSticker?: number;

  rushCostsEuroCents: number;
  _rushCostsEuros?: number;

  labelServiceDiscountPercentage: number;

  constructor() {
  }

  convertCentsToEuros?() {
    this._rushCostsEuros = CentsToEurosPipe.getEurosFromCents(this.rushCostsEuroCents);
    this._baseEuros = CentsToEurosPipe.getEurosFromCents(this.baseEuroCents);
    this._eurosPerSticker = CentsToEurosPipe.getEurosFromCents(this.euroCentsPerSticker);
  }
}
