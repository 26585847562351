<div class="filter-buttons">
  <button mat-stroked-button color="primary"
          class="above-table-button"
          [matMenuTriggerFor]="menu"
          [matBadge]="appliedFiltersCount === 0 ? '' : appliedFiltersCount.toString()"
          [matBadgeColor]="'accent'"
          #menuTrigger="matMenuTrigger">
    <mat-icon>filter_alt</mat-icon>
    Zoek & Filter op Categorieën
  </button>

  <a *ngIf="appliedFiltersCount > 0"
     mat-icon-button
     color="warn"
     [matTooltip]="'Verwijder alle filters'"
     (click)="onClearFiltersClick()">
    <mat-icon>clear</mat-icon>
  </a>
</div>

<mat-menu #menu="matMenu" class="default-table-wrapper__panel" backdropClass="default-table-wrapper__backdrop">
  <form *ngIf="form"
        [formGroup]="form"
        (submit)="onSubmit()"
        (keydown.tab)="$event.stopPropagation()"
        (click)="$event.stopPropagation()"
        class="default-table-wrapper__filter-form">

    <div class="filters">
      <mat-form-field>
        <mat-label>Zoek in categorieën</mat-label>
        <input matInput type="text" formControlName="query" autofocus #searchInput>
        <a *ngIf="form.get('query').value"
           mat-button matSuffix mat-icon-button
           aria-label="Maak leeg"
           [tabIndex]="-1"
           (click)="form.get('query').setValue('')">
          <mat-icon>close</mat-icon>
        </a>
      </mat-form-field>
    </div>

    <div class="buttons">
      <button mat-flat-button color="primary"
              [ngClass]="{'untouched-form': form.untouched && form.pristine }">
        <mat-icon>filter_alt</mat-icon>
        {{applyFiltersButtonText}}
      </button>

      <a mat-icon-button
         color="warn"
         [disabled]="selectedFiltersCount === 0"
         [matTooltip]="'Verwijder alle filters'"
         (click)="onClearFiltersClick()">
        <mat-icon>clear</mat-icon>
      </a>
    </div>
  </form>
</mat-menu>


