<ng-container *ngFor="let breadCrumb of breadCrumbs">
  <a *ngIf="breadCrumb.url; else showStaticText"
     routerLink="{{breadCrumb.url}}"
     class="breadcrumb">
    <span class="item-inner">{{breadCrumb.title}}</span>
  </a>

  <ng-template #showStaticText>
    <span *ngIf="!breadCrumb.url" class="breadcrumb">
      <span class="item-inner">{{breadCrumb.title}}</span>
    </span>
  </ng-template>
</ng-container>
