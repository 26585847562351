import {ExperiTableFilters} from "@app/shared/components/experi-table/experi-table-filters.model";

export class ProductGroupsFilter extends ExperiTableFilters {
  query?: string;

  getTrimmedFilters(): ProductGroupsFilter {
    const trimmedFilters: ProductGroupsFilter = Object.assign(new ProductGroupsFilter(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
