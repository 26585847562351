import {Injectable} from '@angular/core';
import {User} from '../models/user.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {appConfig} from "../app-config";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user: User;
  private user: BehaviorSubject<User>;
  user$: Observable<User>;

  constructor() {
    this._user = null;
    this.user = <BehaviorSubject<User>>new BehaviorSubject(null);
    this.user$ = this.user.asObservable();
  }

  private triggerUpdate() {
    this.user.next(Object.assign(new User(), this._user));
  }

  setUser(user: User) {
    this._user = Object.assign(new User(), user);
    this.updateUser();
  }

  private updateUser() {
    localStorage.setItem(appConfig.localStorageKeys.user, JSON.stringify(this._user));
    this.triggerUpdate();
  }

  unSetUser() {
    this._user = null;
    localStorage.removeItem(appConfig.localStorageKeys.user);
    this.triggerUpdate();
  }

  getUser(): User {
    return this._user;
  }

  setApiTokenExpire(apiTokenExpire) {
    if (!this._user) {
      return null;
    }
    this._user.apiTokenExpire = apiTokenExpire;
    this.updateUser();
  }
}
