import {Component, Input, OnInit} from '@angular/core';
import {Breadcrumb} from "@app/core";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadCrumbsComponent implements OnInit {
  @Input() breadCrumbs: Breadcrumb[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

}
