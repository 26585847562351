export class ProjectsFilter {
  isActive?: boolean;
  query?: string;

  getTrimmedFilters(): ProjectsFilter {
    const trimmedFilters: ProjectsFilter = Object.assign(new ProjectsFilter(), {});

    if (this.isActive === true || this.isActive === false) {
      trimmedFilters['isActive'] = this.isActive;
    }

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
