import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UserService} from '../services/user.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ApiErrorDialogComponent} from '../../shared/components/dialogs/api-error-dialog/api-error-dialog.component';
import {Router} from "@angular/router";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  transFormedHeaders = {};

  constructor(
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const transformedRequest = this.getTransformedRequest(request);
    return next.handle(transformedRequest).pipe(
      tap(
        (response) => {
          if (response instanceof HttpResponse) {
            this.handleHttpResponse(response);
          }
        },
        (response: HttpErrorResponse) => {
          this.handleErrorResponse(response);
        }
      )
    );
  }

  private getTransformedRequest(request: HttpRequest<any>) {
    this.transFormedHeaders = {};
    this.transFormedHeaders['Accept'] = 'application/json';

    const user = this.userService.getUser();
    if (user) {
      this.transFormedHeaders['apitoken'] = user.apiToken;
    }

    return request.clone({
      url: this.getTransformedUrl(request.url),
      setHeaders: this.transFormedHeaders
    });
  }

  private getTransformedUrl(url: string): string {
    if (url.split('/assets/version.json').length > 1) {
      return url;
    }
    if (environment.useProxy) {
      return `proxyApi/${environment.apiPrefix}/${url}/`;
    } else {
      return `${environment.apiBaseUrl}/${environment.apiPrefix}/${url}/`;
    }
  }

  private handleHttpResponse(response: HttpResponse<any>) {
    const apiTokenExpire = response.headers.get('apitoken-expires');
    if (!apiTokenExpire) {
      return null;
    }
    this.userService.setApiTokenExpire(apiTokenExpire);
  }

  private handleErrorResponse(response: HttpErrorResponse) {
    if (response.url.split('/assets/version.json').length > 1) {
      return;
    }
    if (response.status === 401) {
      this.userService.unSetUser();
      this.router.navigateByUrl('/auth/login');
      return null;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = response;
    this.dialog.open(ApiErrorDialogComponent, dialogConfig);
  }
}
