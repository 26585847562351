import {Component, Inject, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-api-error-dialog',
  templateUrl: './api-error-dialog.component.html',
  styleUrls: ['./api-error-dialog.component.scss']
})
export class ApiErrorDialogComponent implements OnInit {
  httpErrorResponse: HttpErrorResponse
  error: string;
  message: string;

  constructor(
    private dialogRef: MatDialogRef<ApiErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) httpErrorResponse: HttpErrorResponse
  ) {
    this.httpErrorResponse = httpErrorResponse;
  }

  ngOnInit(): void {
    this.error = JSON.stringify(this.httpErrorResponse.error);
    this.message = JSON.stringify(this.httpErrorResponse.message);
  }

  close() {
    this.dialogRef.close();
  }
}
