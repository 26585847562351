export class Project {
  id: string;
  name: string;
  projectNumber: string;
  customerName: string;
  createdByUserName: string;
  createdDate: Date;
  startDate: Date;
  isActive: boolean;
}
