import {Plan} from "./plan.model";

export class Company {
  id?: string;
  companyName?: string;
  emailAddress?: string;
  address?: string;
  city?: string;
  zipcode?: string;

  invoiceFrequency?: 'month' | 'year';
  planId?: string;
  plan?: Plan;
  planName?: string;

  moduleExamination?: boolean;
  moduleProjects?: boolean;
  usersCount?: number;
  _usersMax: boolean;
  planMaxUsers?: number;
  productGroupsCount?: number;
  _productGroupsMax: boolean;
  planMaxProductGroups?: number;
  productItemsCount?: number;
  _productItemsMax?: boolean;
  planMaxProductItems?: number;

  publicProductGroupsPageIsEnabled?: boolean;

  createDateTime?: Date;
  updatedDateTime?: Date;
  updatedByUserName?: string;
  updatedByUserId?: string;
  isBlocked?: boolean;
  mainContactUserName?: string;
  mainContactUserId?: string;
  expiredProductItemExaminationsCount?: number;
  activeProjectsCount?: number;
  selectProjectRequiredOnLoanStart: boolean;
}
