import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule, Optional, SkipSelf} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import {throwIfAlreadyLoaded} from './guards/module-import.guard';
import {CurrencyPipe, DatePipe, DecimalPipe, registerLocaleData} from '@angular/common';

import {ApiInterceptor} from './interceptors/api-interceptor.service';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    // Pipes
    DecimalPipe,
    DatePipe,
    CurrencyPipe,

    // Defaults
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'
    },
    {
      provide: LOCALE_ID, useValue: 'nl-NL'
    },
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    registerLocaleData(localeNl);
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

