<span #buttonText class="visually-hidden">Verbind</span>

<h2>WebSocket fout</h2>

<mat-dialog-content>
  <pre class="error-message">{{errorMessage}}</pre>
</mat-dialog-content>

<mat-dialog-actions>

  <button *ngIf="user" class="mat-raised-button" (click)="onLogOutClick()">Uitloggen</button>

  <mat-spinner-button *ngIf="button"
                      (btnClick)="onReconnectClick()"
                      [options]="button"></mat-spinner-button>

</mat-dialog-actions>
