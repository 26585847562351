import {Address} from "@app/core";
import {CentsToEurosPipe} from "@app/dashboard/modules/dashboard-shared/pipes/cents-to-euros.pipe";

export class QrStickersOrder {
  id: number;
  stickers: {
    productItemName?: string;
    productItemId: string;
    amount: number;
  }[] = [];
  stickersCount: number;
  invoiceAddress: Address;
  deliveryAddress: Address;
  isRushOrder = false;
  userName?: string;

  stickersEuroCents = 0;
  stickersDiscountEuroCents = 0;
  stickersAfterDiscountEuroCents = 0;

  rushEuroCents = 0;

  exclVatEuroCents = 0;
  _exclVatEuros = 0;
  vatEuroCents = 0;

  inclVatEuroCents = 0;
  _inclVatEuros = 0;

  createdDate: Date;
  status: string;

  deliveryAddressAddress?: string;
  deliveryAddressCity?: string;
  deliveryAddressZipcode?: string;

  invoiceAddressAddress?: string;
  invoiceAddressCity?: string;
  invoiceAddressZipcode?: string;

  convertCentsToEuros?() {
    this._exclVatEuros = CentsToEurosPipe.getEurosFromCents(this.exclVatEuroCents);
    this._inclVatEuros = CentsToEurosPipe.getEurosFromCents(this.inclVatEuroCents);
  }

  deFlattenAddresses?() {
    this.deliveryAddress = {
      address: this.deliveryAddressAddress,
      city: this.deliveryAddressCity,
      zipcode: this.deliveryAddressZipcode,
    }
    this.invoiceAddress = {
      address: this.invoiceAddressAddress,
      city: this.invoiceAddressCity,
      zipcode: this.invoiceAddressZipcode,
    }
  }
}
