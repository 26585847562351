import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export type InfoPaneStyle = 'calm' | 'notification' | 'warning' | 'positive';

@Component({
  selector: 'app-info-pane',
  templateUrl: './info-pane.component.html',
  styleUrls: ['./info-pane.component.scss']
})
export class InfoPaneComponent implements OnInit {
  @Input() animateIn = false;
  @Input() text: string = '';
  @Input() icon: string = 'info';
  @Input() size: 'small' | 'med' | 'large' = 'med';
  @Input() infoPaneStyle: InfoPaneStyle = 'calm';
  @Input() canClose = false;
  @Input() closeIcon = 'close';
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onCloseClick() {
    if (this.onClose) {
      this.onClose.emit();
    }
  }

}
