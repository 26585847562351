export class Loan {
  id: string;

  productGroupName?: string;
  productGroupImgPath?: string;
  productGroupId?: string;
  productItemId: string;
  productItemName: string;

  userId?: string;
  userName?: string;

  dateStart: Date;
  dateEnd: Date;

  isArtificial: boolean;
}
