import {EventEmitter, Injectable} from "@angular/core";
import {Subscription} from "rxjs";
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  environment = environment;
  private webSocket$: Subscription;
  webSocketAutoRetryPending = false;
  webSocket: WebSocketSubject<{ action: string; data: any }>;
  webSocketConnectedCount = 0;
  webSocketError$: EventEmitter<string> = new EventEmitter<string>();
  webSocketNext$: EventEmitter<string> = new EventEmitter<string>();
  webSocketComplete$: EventEmitter<void> = new EventEmitter<void>();
  webSocketConnected$: EventEmitter<void> = new EventEmitter<void>();
  webSocketReconnected$: EventEmitter<void> = new EventEmitter<void>();
  received$: EventEmitter<{ action: string[], data: any }> =
    new EventEmitter<{ action: string[]; data: any }>(null);

  constructor() {
  }

  connect(id: {apiToken?: string, publicCompanyId?: string}) {
    if (this.webSocket$) {
      this.webSocket$.unsubscribe();
    }
    this.webSocket = webSocket({
      url: this.getWebSocketConnectUrl(id)
    });
    this.webSocket$ = this.webSocket.subscribe(
      message => {
        this.webSocketAutoRetryPending = false;
        this.handleIncomingMessage(message);
      },
      err => {
        this.handleError(err);
      },
      () => {
        this.webSocketComplete$.emit();
      }
    );
    if (!(this.webSocket$ instanceof Subscription)) {
      return
    }
    this.webSocketConnectedCount++;
    if (this.webSocketConnectedCount > 1) {
      this.webSocketReconnected$.emit();
    } else {
      this.webSocketConnected$.emit();
    }
  }

  private getWebSocketConnectUrl(id: {apiToken?: string, publicCompanyId?: string}): string {
    if (id.apiToken) {
      return `${environment.ws}?apiToken=${id.apiToken}&version=${this.environment.version}`;
    }
    if (id.publicCompanyId) {
      return `${environment.ws}?publicCompanyId=${id.publicCompanyId}&version=${this.environment.version}`;
    }

    return `${environment.ws}?version=${this.environment.version}`;
  }

  private handleIncomingMessage(message: { action: string, data: any }) {
    this.webSocketNext$.emit();
    this.webSocketError$.emit(null);
    this.received$.emit({
      action: message.action.split('/'),
      data: message.data
    });
  }

  private handleError(event: Event | CloseEvent) {
    if (event instanceof Event) {
      this.webSocketError$.emit('Unexpected WS-error');
    }
    if (event instanceof CloseEvent) {
      this.webSocketError$.emit(`WS close event: ${event.code}`);
    }
    if (this.webSocket) {
      this.webSocket.unsubscribe();
    }
    if (this.webSocket$) {
      this.webSocket$.unsubscribe();
    }
  }

  clear() {
    this.webSocketAutoRetryPending = false;
    if (this.webSocket) {
      this.webSocket.complete();
    }
    if (this.webSocket$) {
      this.webSocket$.unsubscribe();
    }
  }
}
