// Angular
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// Modules
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {AngularPageVisibilityModule} from "angular-page-visibility";

// AppModule
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

// Components
import {VersionCheckerComponent} from '@app/version-checker.component';

@NgModule({
  declarations: [
    AppComponent,
    VersionCheckerComponent
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Modules
    CoreModule,
    SharedModule,
    AngularPageVisibilityModule,

    // App
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}
