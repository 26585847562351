import {Loan} from "./loan.model";
import {DamageReport} from "./damage-report.model";
import {ProductItemExamination} from "./product-item-examination.model";

export class ProductItem {
  _currentUserNotLoanerOfProductItem?: boolean;
  id?: string;
  name?: string;
  qrCodeImagePath?: string;
  qrStickerOrderedCount: number;

  productGroupId?: string;
  productGroupImgPath?: string;
  productGroupName?: string;
  productGroupDescription?: string;
  productGroupComment?: string;
  productGroupExaminationIntervalMonths: string;

  isAvailable?: boolean;
  loanedByUserId?: string;
  loanedByUserName?: string;
  loanedSinceDate?: Date;
  loanedEndedDate?: Date;

  outOfServiceReason: string;

  filesTotal?: number;

  loansTotal?: number;
  loans?: Loan[];

  damageReportsTotal?: number;
  damageReportsRepairedTotal?: number;
  damageReportsBrokenTotal?: number;
  damageReports?: DamageReport[];

  productItemExaminationsTotal?: number;
  latestProductItemExaminationDate?: Date;
  latestProductItemExaminationDescription: Date;
  productItemExaminations?: ProductItemExamination[];
  productItemExamination?: ProductItemExamination;
  productItemExaminationDeadlineDate?: Date;
}
