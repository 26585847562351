export class DamageReport {
  id: string;
  isRepaired: boolean | undefined;
  createdDate: Date;
  productGroupName?: string;
  productGroupImgPath?: string;
  productGroupId?: string;
  productItemId?: string;
  productItemName?: string;

  userId?: string;
  userName?: string;

  imagePaths?: string[];
  remark?: string;
}
