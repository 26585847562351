import {CentsToEurosPipe} from "@app/dashboard/modules/dashboard-shared/pipes/cents-to-euros.pipe";
import {EurosToCentsPipe} from "@app/dashboard/modules/dashboard-shared/pipes/euros-to-cents.pipe";

export class Plan {
  disabled? = false;
  id?: string;
  companiesCount?: number;
  name: string;
  description?: string;
  isCustomPlan: boolean;
  isBlocked: boolean;

  maxUsers: number;
  maxProductGroups: number;
  maxProductItems: number;

  euroCentsPerMonth?: number;
  _eurosPerMonth?: number;
  euroCentsPerYear?: number;
  _eurosPerYear?: number;

  labelServiceDiscountPercentage?: number;

  moduleExaminationEuroCentsPerMonth: number;
  _moduleExaminationEurosPerMonth: number;
  moduleExaminationEuroCentsPerYear: number;
  _moduleExaminationEurosPerYear: number;

  moduleProjectsEuroCentsPerMonth: number;
  _moduleProjectsEurosPerMonth: number;
  moduleProjectsEuroCentsPerYear: number;
  _moduleProjectsEurosPerYear: number;

  convertCentsToEuros?() {
    this._eurosPerMonth = CentsToEurosPipe.getEurosFromCents(this.euroCentsPerMonth);
    this._eurosPerYear = CentsToEurosPipe.getEurosFromCents(this.euroCentsPerYear);
    this._moduleExaminationEurosPerMonth = CentsToEurosPipe.getEurosFromCents(this.moduleExaminationEuroCentsPerMonth);
    this._moduleExaminationEurosPerYear = CentsToEurosPipe.getEurosFromCents(this.moduleExaminationEuroCentsPerYear);
    this._moduleProjectsEurosPerMonth = CentsToEurosPipe.getEurosFromCents(this.moduleProjectsEuroCentsPerMonth);
    this._moduleProjectsEurosPerYear = CentsToEurosPipe.getEurosFromCents(this.moduleProjectsEuroCentsPerYear);
  }

  convertEurosToCents?(deleteEuroVars = true) {
    this.euroCentsPerMonth = EurosToCentsPipe.getCentsFromEuros(this._eurosPerMonth);
    this.euroCentsPerYear = EurosToCentsPipe.getCentsFromEuros(this._eurosPerYear);
    this.moduleExaminationEuroCentsPerMonth = EurosToCentsPipe.getCentsFromEuros(this._moduleExaminationEurosPerMonth);
    this.moduleExaminationEuroCentsPerYear = EurosToCentsPipe.getCentsFromEuros(this._moduleExaminationEurosPerYear);
    this.moduleProjectsEuroCentsPerMonth = EurosToCentsPipe.getCentsFromEuros(this._moduleProjectsEurosPerMonth);
    this.moduleProjectsEuroCentsPerYear = EurosToCentsPipe.getCentsFromEuros(this._moduleProjectsEurosPerYear);
    if (deleteEuroVars) {
      delete this._eurosPerMonth;
      delete this._eurosPerYear;
      delete this._moduleExaminationEurosPerMonth;
      delete this._moduleExaminationEurosPerYear;
      delete this._moduleProjectsEurosPerMonth;
      delete this._moduleProjectsEurosPerYear;
    }
  }
}
