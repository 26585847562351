export class ProductItemExamination {
  _deletePending?: boolean;
  id?: string;
  deadlineDate?: Date;
  description?: string;
  examinationDate?: Date;

  productGroupId?: string;
  productGroupName?: string;
  productItemId?: string;
  productItemName?: string;

  examinationIntervalMonths?: number | null;
}
