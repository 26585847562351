export class ProductItemsFilter {
  query?: string;
  examinationRequired?: boolean;
  examinationExpireStatus?: 'EXPIRED' | '30_DAYS' | '60_DAYS' | '90_DAYS';

  getTrimmedFilters(): ProductItemsFilter {
    const trimmedFilters: ProductItemsFilter = Object.assign(new ProductItemsFilter(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }
    if (this.examinationRequired === true || this.examinationRequired === false) {
      trimmedFilters['examinationRequired'] = this.examinationRequired;
    }
    if (this.examinationExpireStatus) {
      trimmedFilters['examinationExpireStatus'] = this.examinationExpireStatus;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
